import React, { useEffect, useState } from 'react';
import { Fab } from '@material-ui/core';
import { Character, Villager } from '../game/Villager';
import PersonIcon from '@material-ui/icons/Person';
import { VillageChooserCard } from './VillagerChooserCard';
import { CupidCard } from './CupidCard';
import { WitchCard } from './WitchDialog';
import { PlayerDialog } from './PlayerDialog';
import { WinnerCard } from './WinnerCard';
import { Step, withNextStep, GameState, getInitialGameState } from '../game/GameState';
import { DeathCard } from './DeathCard';
import { CustomCard } from './CustomCard';
import { getDeaths, getUpdatedVillagers } from '../game/Death';
import { useTranslation } from 'react-i18next';

interface GameProperties {
    startVillagers: Villager[];
    onDaytimeChanged: (isDay: boolean) => void;
    onCloseGame: () => void;
}

export default function Game({ startVillagers, onDaytimeChanged, onCloseGame }: GameProperties) {
    const { t } = useTranslation();
    const [gameState, setGameState] = useState<GameState>(getInitialGameState(startVillagers));
    const [showPlayers, setShowPlayers] = useState(false);

    useEffect(() => {
        const isDay = gameState.step >= Step.EVERYONE_WAKES_UP;
        onDaytimeChanged(isDay);
    });

    const nextStep = (withProperties: Partial<GameState> = {}) =>
        setGameState(withNextStep({ ...gameState, ...withProperties }));

    const aliveVillagers = gameState.villagers.filter(villager => villager.isAlive);

    var card: any = null;
    switch (gameState.step) {
        case Step.START:
        case Step.EVERYONE_FALLS_ASLEEP:
            card = (
                <CustomCard title={t('narrator.village.fallsAsleep')} onClick={() => nextStep()} />
            );
            break;
        case Step.CUPID:
            card = (
                <CupidCard
                    villagers={aliveVillagers}
                    onSubmit={(chosenVillager1, chosenVillager2) =>
                        nextStep({
                            cupid: {
                                lover1VillagerId: chosenVillager1.id,
                                lover2VillagerId: chosenVillager2.id,
                            },
                        })
                    }
                />
            );
            break;
        case Step.WEREWOLVES:
            card = (
                <VillageChooserCard
                    title={t('narrator.werewolves.wakeUp')}
                    question={t('narrator.werewolves.question')}
                    villagers={aliveVillagers.filter(
                        villager => villager.character !== Character.WEREWOLF
                    )}
                    onSubmit={chosenVillager => nextStep({ victimVillagerId: chosenVillager!.id })}
                />
            );
            break;
        case Step.WITCH:
            card = (
                <WitchCard
                    usedResurrectionPotion={gameState.witch.usedResurrectionPotion}
                    usedDeathPotion={gameState.witch.usedDeathPotion}
                    victim={
                        gameState.villagers.find(
                            villager => villager.id === gameState.victimVillagerId
                        )!
                    }
                    villagers={aliveVillagers}
                    onSubmit={(protectVictim: boolean, additionalVictim: Villager | null) =>
                        nextStep({
                            witch: {
                                usedResurrectionPotion:
                                    gameState.witch.usedResurrectionPotion || protectVictim,
                                usedDeathPotion:
                                    gameState.witch.usedDeathPotion || additionalVictim !== null,
                                savesVictim: protectVictim,
                                poisonedVillagerId: additionalVictim?.id || null,
                            },
                        })
                    }
                />
            );
            break;
        case Step.SEER:
            card = (
                <VillageChooserCard
                    title={t('narrator.seer.wakesUp')}
                    question={t('narrator.seer.question')}
                    villagers={aliveVillagers.filter(
                        villager => villager.character !== Character.SEER
                    )}
                    onSubmit={chosenVillager =>
                        nextStep({ revealedVillagerId: chosenVillager!.id })
                    }
                />
            );
            break;
        case Step.SEER_REVELATION:
            const revealedVillager = gameState.villagers.find(
                villager => villager.id === gameState.revealedVillagerId
            )!;
            card = (
                <CustomCard
                    title={t('narrator.seer.answer', {
                        name: revealedVillager.name,
                        what: t(
                            revealedVillager!.character !== Character.WEREWOLF
                                ? 'narrator.seer.good'
                                : 'narrator.seer.bad'
                        ),
                    })}
                    onClick={() => nextStep()}
                />
            );
            break;
        case Step.GUARDIAN:
            card = (
                <VillageChooserCard
                    title={t('narrator.guardian.wakesUp')}
                    question={t('narrator.guardian.question')}
                    villagers={aliveVillagers.filter(
                        villager => villager.id !== gameState.lastGuardedVillagerId
                    )}
                    onSubmit={chosenVillager =>
                        nextStep({ lastGuardedVillagerId: chosenVillager!.id })
                    }
                />
            );
            break;
        case Step.EVERYONE_WAKES_UP:
            const nightDeaths = getDeaths(gameState);
            card = (
                <DeathCard
                    title={t('narrator.village.wakesUp')}
                    deaths={nightDeaths}
                    onNext={() =>
                        nextStep({ villagers: getUpdatedVillagers(gameState, nightDeaths) })
                    }
                />
            );
            break;
        case Step.ELECTION:
            card = (
                <VillageChooserCard
                    title={t('narrator.village.election')}
                    question={t('narrator.village.exileQuestion')}
                    villagers={aliveVillagers}
                    includeEmptyOption={true}
                    onSubmit={chosenVillager =>
                        nextStep({ electedVillagerId: chosenVillager?.id || null })
                    }
                />
            );
            break;
        case Step.EXILE:
            const dayDeaths = getDeaths(gameState);
            card = (
                <DeathCard
                    title={t('narrator.village.exiledVillagers')}
                    deaths={dayDeaths}
                    onNext={() =>
                        nextStep({ villagers: getUpdatedVillagers(gameState, dayDeaths) })
                    }
                />
            );
            break;
        case Step.WINNER_VILLAGERS:
        case Step.WINNER_WEREWOLVES:
        case Step.WINNER_COUPLE:
        case Step.WINNER_TIE:
            return <WinnerCard winner={gameState.step} onCloseGame={onCloseGame} />;
    }

    return (
        <>
            {card}
            <Fab
                variant="extended"
                size="medium"
                color="primary"
                style={{ position: 'fixed', bottom: '20px', left: 0, right: 0, margin: '0 auto' }}
                onClick={() => setShowPlayers(true)}
            >
                <PersonIcon style={{ marginRight: '8px' }} />
                {t('settings.players')}
            </Fab>
            <PlayerDialog
                couple={gameState.cupid}
                villagers={gameState.villagers}
                isOpen={showPlayers}
                onClose={() => setShowPlayers(false)}
            />
        </>
    );
}
