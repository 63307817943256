import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import React from 'react';
import PersonIcon from '@material-ui/icons/Person';
import { CustomCard } from './CustomCard';
import { Death } from 'game/Death';
import { useTranslation } from 'react-i18next';

interface DeathCardProperties {
    title: string;
    deaths: Death[];
    onNext: () => void;
}

export function DeathCard({ title, deaths, onNext }: DeathCardProperties) {
    const { t } = useTranslation();

    if (deaths.length === 0) {
        return <CustomCard title={t('narrator.village.nobodyDied')} onClick={onNext} />;
    }

    return (
        <CustomCard title={title} onClick={onNext}>
            <List>
                {deaths.map(death => (
                    <ListItem key={death.villager.name}>
                        <ListItemAvatar>
                            <Avatar>
                                <PersonIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={death.villager.name}
                            secondary={t(death.causeOfDeath)}
                        />
                    </ListItem>
                ))}
            </List>
        </CustomCard>
    );
}
