import { FormGroup } from '@material-ui/core';
import React, { useState } from 'react';
import { Villager } from '../game/Villager';
import { CustomCard } from './CustomCard';
import { VillagerChooser } from './VillagerChooser';
import { useTranslation } from 'react-i18next';

interface CupidCardProperties {
    villagers: Villager[];
    onSubmit: (chosenVillager1: Villager, chosenVillager2: Villager) => void;
}

export function CupidCard({ villagers, onSubmit }: CupidCardProperties) {
    const { t } = useTranslation();
    const [chosenVillager1, setChosenVillager1] = useState<Villager | null>(null);
    const [chosenVillager2, setChosenVillager2] = useState<Villager | null>(null);

    return (
        <CustomCard
            title={t('narrator.cupid.wakesUp')}
            buttonDisabled={
                chosenVillager1 !== null &&
                chosenVillager2 !== null &&
                chosenVillager1.id === chosenVillager2.id
            }
            onClick={() => onSubmit(chosenVillager1!, chosenVillager2!)}
        >
            <FormGroup row={false}>
                <VillagerChooser
                    message={t('narrator.cupid.firstLover')}
                    villagers={villagers}
                    onSelectedVillagerChanged={villager => setChosenVillager1(villager)}
                />
                <br />
                <VillagerChooser
                    message={t('narrator.cupid.secondLover')}
                    villagers={villagers}
                    onSelectedVillagerChanged={villager => setChosenVillager2(villager)}
                />
            </FormGroup>
        </CustomCard>
    );
}
