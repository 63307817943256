import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { Villager } from '../game/Villager';
import { CustomCard } from './CustomCard';
import { useTranslation } from 'react-i18next';

interface PlayerCardsProperties {
    villagers: Villager[];
    onDone: () => void;
}

export function PlayerCharacters({ villagers, onDone }: PlayerCardsProperties) {
    const { t } = useTranslation();
    const [index, setIndex] = useState(0);
    const [revealed, setRevealed] = useState(false);

    const onClick = () => {
        if (!revealed) {
            setRevealed(true);
        } else if (index !== villagers.length - 1) {
            setIndex(index + 1);
            setRevealed(false);
        } else {
            onDone();
        }
    };

    const currentVillager = villagers[index];
    return (
        <CustomCard
            title={currentVillager.name}
            buttonTitle={revealed ? t('input.next') : t('input.reveal')}
            onClick={onClick}
        >
            {revealed && (
                <Typography variant="h6" style={{ textAlign: 'center' }}>
                    {t(currentVillager.character)}
                </Typography>
            )}
        </CustomCard>
    );
}
