import { GameState } from './GameState';
import { Villager } from './Villager';

export enum CauseOfDeath {
    MURDERED = 'causeOfDeath.murdered',
    POISONED = 'causeOfDeath.poisoned',
    BROKEN_HEART = 'causeOfDeath.brokenHeart',
    ELECTION = 'causeOfDeath.election',
}

export interface Death {
    villager: Villager;
    causeOfDeath: CauseOfDeath;
}

const getCauseOfDeath = (villagerId: string, gameState: GameState): CauseOfDeath | null => {
    if (
        villagerId === gameState.victimVillagerId &&
        villagerId !== gameState.lastGuardedVillagerId &&
        !gameState.witch.savesVictim
    ) {
        return CauseOfDeath.MURDERED;
    } else if (villagerId === gameState.witch.poisonedVillagerId) {
        return CauseOfDeath.POISONED;
    } else if (villagerId === gameState.electedVillagerId) {
        return CauseOfDeath.ELECTION;
    } else {
        return null;
    }
};

export const getDeaths = (gameState: GameState): Death[] => {
    const deaths: Death[] = [];
    gameState.villagers
        .filter(villager => villager.isAlive)
        .forEach(villager => {
            const causeOfDeath = getCauseOfDeath(villager.id, gameState);
            if (causeOfDeath) {
                deaths.push({ villager, causeOfDeath });
            } else if (
                (villager.id === gameState.cupid.lover1VillagerId &&
                    gameState.cupid.lover2VillagerId !== null &&
                    getCauseOfDeath(gameState.cupid.lover2VillagerId, gameState) !== null) ||
                (villager.id === gameState.cupid.lover2VillagerId &&
                    gameState.cupid.lover1VillagerId !== null &&
                    getCauseOfDeath(gameState.cupid.lover1VillagerId, gameState) !== null)
            ) {
                deaths.push({ villager, causeOfDeath: CauseOfDeath.BROKEN_HEART });
            }
        });
    return deaths;
};

export const getUpdatedVillagers = (gameState: GameState, deaths: Death[]): Villager[] => {
    const deathVillagers = deaths.map(death => death.villager);
    const deathVillagerIds = deathVillagers.map(deathVillager => deathVillager.id);
    return [
        ...gameState.villagers.filter(villager => !deathVillagerIds.includes(villager.id)),
        ...deathVillagers.map(deathVillager => ({
            ...deathVillager,
            isAlive: false,
        })),
    ];
};
