import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Slider,
    Switch,
    TextField,
    Typography,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { Character, Villager } from 'game/Villager';
import React, { useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { shuffle } from 'utils/shuffle';
import { uuid } from 'utils/uuid';
import { useTranslation } from 'react-i18next';

interface GameCreationCardProperties {
    onGameCreated: (villagers: Villager[]) => void;
}

export function GameCreationCard({ onGameCreated }: GameCreationCardProperties) {
    const { t } = useTranslation();
    const [playerName, setPlayerName] = useState('');
    const [playerNames, setPlayerNames] = useState<string[]>([]);
    const [countWerewolves, setCountWerewolves] = useState(1);
    const [additionalCharacters, setAdditionalCharacters] = useState<Character[]>([]);

    const countRoles = countWerewolves + additionalCharacters.length;

    const switches: Character[] = [
        Character.WITCH,
        Character.SEER,
        Character.GUARDIAN,
        Character.CUPID,
    ];

    const marks = new Array(5).fill(0).map((_, i) => ({ value: i + 1, label: i + 1 }));

    const onChange = (character: Character, value: boolean) => {
        setAdditionalCharacters(
            value
                ? [...additionalCharacters, character]
                : additionalCharacters.filter(
                      additionalCharacter => additionalCharacter !== character
                  )
        );
    };

    const onKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            const newPlayerName = event.target.value;
            if (!playerNames.includes(newPlayerName)) {
                setPlayerNames([...playerNames, newPlayerName]);
            }
            setPlayerName('');
        }
    };

    const onDelete = (playerName: string) => {
        setPlayerNames(playerNames.filter(it => it !== playerName));
    };

    const createVillager = (name: string, character: Character): Villager => ({
        id: uuid(),
        name,
        character,
        isAlive: true,
    });

    const onSubmit = () => {
        const names = shuffle(playerNames);
        const werewolves = Array(countWerewolves)
            .fill(null)
            .map(() => createVillager(names.pop() || '', Character.WEREWOLF));
        const villagers = [
            ...werewolves,
            ...additionalCharacters.map(character => createVillager(names.pop() || '', character)),
            ...names.map(name => createVillager(name, Character.ORDINARY_VILLAGER)),
        ];
        onGameCreated(villagers);
    };

    return (
        <Card style={{ width: '340px' }}>
            <CardContent>
                <Typography variant="h5">{t('settings.newGame')}</Typography>
                <br />
                <FormLabel component="legend">{t('settings.countOfWerewolves')}</FormLabel>
                <Slider
                    defaultValue={1}
                    valueLabelDisplay="auto"
                    step={1}
                    marks={marks}
                    min={1}
                    max={5}
                    color="secondary"
                    value={countWerewolves}
                    onChange={(_, value) =>
                        setCountWerewolves(Array.isArray(value) ? value[0] : value)
                    }
                />
                <br />
                <br />
                <FormControl component="fieldset">
                    <FormLabel component="legend">{t('settings.settings')}</FormLabel>
                    <FormGroup>
                        {switches.map(character => (
                            <FormControlLabel
                                key={character}
                                control={
                                    <Switch
                                        checked={additionalCharacters.includes(character)}
                                        name={character}
                                        onChange={event =>
                                            onChange(character, event.target.checked)
                                        }
                                    />
                                }
                                label={t('settings.includeCharacter', {
                                    character: t(character),
                                })}
                            />
                        ))}
                    </FormGroup>
                </FormControl>
                <br />
                <br />
                <Box boxShadow={1}>
                    <TextField
                        id="filled-basic"
                        label={t('settings.players')}
                        variant="filled"
                        fullWidth
                        onKeyDown={onKeyDown}
                        onChange={event => setPlayerName(event.target.value)}
                        value={playerName}
                    />
                    {playerNames.length !== 0 && (
                        <List>
                            {playerNames.map(playerName => (
                                <ListItem key={playerName}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <PersonIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={playerName} />
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() => onDelete(playerName)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Box>
            </CardContent>
            <CardActions>
                <Button
                    size="small"
                    color="secondary"
                    onClick={onSubmit}
                    disabled={playerNames.length < countRoles}
                >
                    {t('input.create')}
                </Button>
            </CardActions>
        </Card>
    );
}
