import { Card, CardContent, Typography, CardActions, Button } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface CustomCardProperties {
    title: string;
    children?: any;
    buttonTitle?: string;
    buttonDisabled?: boolean;
    onClick: () => void;
}

export function CustomCard({
    title,
    children,
    buttonTitle,
    buttonDisabled,
    onClick,
}: CustomCardProperties) {
    const { t } = useTranslation();

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" style={{ textAlign: 'center' }}>
                    {title}
                </Typography>
                {children && (
                    <>
                        <br />
                        {children}
                    </>
                )}
            </CardContent>
            <CardActions style={{ justifyContent: 'center' }}>
                <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    disabled={buttonDisabled}
                    onClick={onClick}
                >
                    {buttonTitle || t('input.next')}
                </Button>
            </CardActions>
        </Card>
    );
}
