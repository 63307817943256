import React, { useState } from 'react';
import { Villager } from '../game/Villager';
import { CustomCard } from './CustomCard';
import { VillagerChooser } from './VillagerChooser';

interface VillageChooserCardProperties {
    title: string;
    question: string;
    villagers: Villager[];
    includeEmptyOption?: boolean;
    onSubmit: (chosenVillager: Villager | null) => void;
}

export function VillageChooserCard({
    title,
    question,
    villagers,
    includeEmptyOption,
    onSubmit,
}: VillageChooserCardProperties) {
    const [chosenVillager, setChosenVillager] = useState<Villager | null>(null);

    return (
        <CustomCard
            title={title}
            buttonDisabled={!includeEmptyOption && !chosenVillager}
            onClick={() => onSubmit(chosenVillager!)}
        >
            <VillagerChooser
                message={question}
                villagers={villagers}
                includeEmptyOption={includeEmptyOption}
                onSelectedVillagerChanged={villager => setChosenVillager(villager)}
            />
        </CustomCard>
    );
}
