import React, { useMemo, useState } from 'react';
import './i18n/config';
import Game from './components/Game';
import {
    AppBar,
    createMuiTheme,
    CssBaseline,
    Grid,
    ThemeProvider,
    Toolbar,
    Typography,
} from '@material-ui/core';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import NightsStayIcon from '@material-ui/icons/NightsStay';
import { orange, deepPurple } from '@material-ui/core/colors';
import { GameCreationCard } from 'components/GameCreationCard';
import { Villager } from 'game/Villager';
import { CustomCard } from 'components/CustomCard';
import { PlayerCharacters } from 'components/PlayerCharacters';
import { shuffle } from 'utils/shuffle';
import { useTranslation } from 'react-i18next';

enum AppStep {
    WELCOME,
    GAME_CREATION,
    PLAYER_CARDS,
    GAME_IN_PROGRESS,
}

interface AppState {
    step: AppStep;
    villagers?: Villager[];
}

function App() {
    const { t } = useTranslation();
    const [isDay, setIsDay] = useState(true);
    const [appState, setAppState] = useState<AppState>({ step: AppStep.WELCOME });

    const theme = useMemo(() => {
        return createMuiTheme({
            palette: {
                type: isDay ? 'light' : 'dark',
                primary: {
                    main: deepPurple[900],
                },
                secondary: {
                    main: orange[900],
                },
            },
        });
    }, [isDay]);

    var content: any = null;
    switch (appState.step) {
        case AppStep.WELCOME:
            content = (
                <CustomCard
                    title={t('welcome')}
                    buttonTitle={t('start')}
                    onClick={() => setAppState({ step: AppStep.GAME_CREATION })}
                />
            );
            break;
        case AppStep.GAME_CREATION:
            content = (
                <GameCreationCard
                    onGameCreated={villagers =>
                        setAppState({ step: AppStep.PLAYER_CARDS, villagers })
                    }
                />
            );
            break;
        case AppStep.PLAYER_CARDS:
            content = (
                <PlayerCharacters
                    villagers={shuffle(appState.villagers!)}
                    onDone={() =>
                        setAppState({
                            step: AppStep.GAME_IN_PROGRESS,
                            villagers: appState.villagers,
                        })
                    }
                />
            );
            break;
        case AppStep.GAME_IN_PROGRESS:
            content = (
                <Game
                    startVillagers={appState.villagers!}
                    onDaytimeChanged={(isDay: boolean) => setIsDay(isDay)}
                    onCloseGame={() => setAppState({ step: AppStep.WELCOME })}
                />
            );
            break;
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppBar position="fixed">
                <Toolbar>
                    <Typography variant="h6" style={{ flexGrow: 1 }}>
                        {t('appName')}
                    </Typography>
                    {isDay ? <WbSunnyIcon /> : <NightsStayIcon />}
                </Toolbar>
            </AppBar>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{
                    minHeight: 'calc(100vh - 100px)',
                    marginTop: '80px',
                    marginBottom: '20px',
                }}
            >
                <Grid item xs={10} md={4}>
                    {content}
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}

export default App;
