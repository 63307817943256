import { FormControl, FormLabel, MenuItem, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Villager } from '../game/Villager';
import { useTranslation } from 'react-i18next';

interface VillageChooserProperties {
    message: String;
    villagers: Villager[];
    includeEmptyOption?: boolean;
    onSelectedVillagerChanged: (villager: Villager | null) => void;
}

export function VillagerChooser({
    message,
    villagers,
    includeEmptyOption,
    onSelectedVillagerChanged,
}: VillageChooserProperties) {
    const { t } = useTranslation();
    const [selectedIndex, setSelectedIndex] = useState(includeEmptyOption ? -1 : 0);

    useEffect(() => {
        onSelectedVillagerChanged(selectedIndex === -1 ? null : villagers[selectedIndex]);
    });

    const onChangeHandler = (event: any) => {
        setSelectedIndex(event.target.value);
    };

    return (
        <FormControl fullWidth>
            <FormLabel component="legend" color="secondary">
                {message}
            </FormLabel>
            <Select value={selectedIndex} onChange={onChangeHandler} color="secondary">
                {includeEmptyOption && (
                    <MenuItem key={-1} value={-1}>
                        {t('input.empty')}
                    </MenuItem>
                )}
                {villagers.map((villager, index) => (
                    <MenuItem key={index} value={index}>
                        {villager.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
