import { Step } from 'game/GameState';
import React from 'react';
import { CustomCard } from './CustomCard';
import { useTranslation } from 'react-i18next';

interface WinnerCardProperties {
    winner: Step.WINNER_VILLAGERS | Step.WINNER_WEREWOLVES | Step.WINNER_COUPLE | Step.WINNER_TIE;
    onCloseGame: () => void;
}

export function WinnerCard({ winner, onCloseGame }: WinnerCardProperties) {
    const { t } = useTranslation();

    var winnerText: string = '';
    switch (winner) {
        case Step.WINNER_VILLAGERS:
            winnerText = t('winner.villagers');
            break;
        case Step.WINNER_WEREWOLVES:
            winnerText = t('winner.werewolves');
            break;
        case Step.WINNER_COUPLE:
            winnerText = t('winner.couple');
            break;
        default:
            winnerText = t('winner.tie');
            break;
    }

    return <CustomCard title={winnerText} buttonTitle={t('input.close')} onClick={onCloseGame} />;
}
