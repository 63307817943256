import { Character, Villager } from './Villager';

export enum Step {
    START,
    CUPID,
    EVERYONE_FALLS_ASLEEP,
    WEREWOLVES,
    WITCH,
    SEER,
    SEER_REVELATION,
    GUARDIAN,
    EVERYONE_WAKES_UP,
    ELECTION,
    EXILE,
    WINNER_VILLAGERS,
    WINNER_WEREWOLVES,
    WINNER_COUPLE,
    WINNER_TIE,
}

export interface GameState {
    villagers: Villager[];
    step: Step;
    cupid: {
        lover1VillagerId: string | null;
        lover2VillagerId: string | null;
    };
    victimVillagerId: string | null;
    witch: {
        usedResurrectionPotion: boolean;
        usedDeathPotion: boolean;
        savesVictim: boolean;
        poisonedVillagerId: string | null;
    };
    revealedVillagerId: string | null;
    lastGuardedVillagerId: string | null;
    electedVillagerId: string | null;
}

const calculateWinner = (gameState: GameState): Step | null => {
    const aliveVillagers = gameState.villagers.filter(villager => villager.isAlive);
    if (
        aliveVillagers.every(
            villager =>
                gameState.cupid.lover1VillagerId === villager.id ||
                gameState.cupid.lover2VillagerId === villager.id
        )
    ) {
        return Step.WINNER_COUPLE;
    }

    const goodPlayersExist = aliveVillagers.some(it => it.character !== Character.WEREWOLF);
    const badPlayersExist = aliveVillagers.some(it => it.character === Character.WEREWOLF);
    if (goodPlayersExist && !badPlayersExist) {
        return Step.WINNER_VILLAGERS;
    } else if (!goodPlayersExist && badPlayersExist) {
        return Step.WINNER_WEREWOLVES;
    } else if (!goodPlayersExist && !badPlayersExist) {
        return Step.WINNER_TIE;
    } else {
        return null;
    }
};

const conditions: { character?: Character; step: Step }[] = [
    { character: Character.CUPID, step: Step.CUPID },
    { step: Step.WEREWOLVES },
    { character: Character.WITCH, step: Step.WITCH },
    { character: Character.SEER, step: Step.SEER },
    { character: Character.SEER, step: Step.SEER_REVELATION },
    { character: Character.GUARDIAN, step: Step.GUARDIAN },
    { step: Step.EVERYONE_WAKES_UP },
    { step: Step.ELECTION },
    { step: Step.EXILE },
];

const isAlive = (villagers: Villager[], character: Character) => {
    const searchedVillager = villagers.find(villager => villager.character === character);
    return searchedVillager !== undefined && searchedVillager.isAlive;
};

export const withNextStep = (gameState: GameState): GameState => {
    const winner = calculateWinner(gameState);
    if (winner) {
        return { ...gameState, step: winner };
    } else {
        const firstMatchingCondition = conditions.find(condition => {
            return (
                (!condition.character && gameState.step < condition.step) ||
                (condition.character &&
                    isAlive(gameState.villagers, condition.character) &&
                    gameState.step < condition.step &&
                    (condition.character !== Character.WITCH ||
                        !gameState.witch.usedDeathPotion ||
                        !gameState.witch.usedResurrectionPotion))
            );
        });

        if (firstMatchingCondition) {
            return { ...gameState, step: firstMatchingCondition.step };
        } else {
            return {
                ...gameState,
                step: Step.EVERYONE_FALLS_ASLEEP,
                victimVillagerId: null,
                witch: {
                    ...gameState.witch,
                    savesVictim: false,
                    poisonedVillagerId: null,
                },
            };
        }
    }
};

export const getInitialGameState = (villagers: Villager[]): GameState => {
    return {
        villagers,
        step: Step.START,
        cupid: {
            lover1VillagerId: null,
            lover2VillagerId: null,
        },
        victimVillagerId: null,
        witch: {
            usedResurrectionPotion: false,
            usedDeathPotion: false,
            savesVictim: false,
            poisonedVillagerId: null,
        },
        revealedVillagerId: null,
        lastGuardedVillagerId: null,
        electedVillagerId: null,
    };
};
