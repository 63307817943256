import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface YesNoQuestionProperties {
    message: string;
    onAnswerChanged: (answer: boolean) => void;
}

export function YesNoQuestion({ message, onAnswerChanged }: YesNoQuestionProperties) {
    const { t } = useTranslation();
    const [answer, setAnswer] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newAnswer = (event.target as HTMLInputElement).value === 'true';
        setAnswer(newAnswer);
        onAnswerChanged(newAnswer);
    };

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">{message}</FormLabel>
            <RadioGroup value={answer} onChange={handleChange} row>
                <FormControlLabel value={true} control={<Radio />} label={t('input.yes')} />
                <FormControlLabel value={false} control={<Radio />} label={t('input.no')} />
            </RadioGroup>
        </FormControl>
    );
}
