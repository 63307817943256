import React, { useState } from 'react';
import { YesNoQuestion } from './YesNoQuestion';
import { VillagerChooser } from './VillagerChooser';
import { Character, Villager } from '../game/Villager';
import { FormGroup } from '@material-ui/core';
import { CustomCard } from './CustomCard';
import { useTranslation } from 'react-i18next';

interface WitchCardProperties {
    usedResurrectionPotion: boolean;
    usedDeathPotion: boolean;
    victim: Villager;
    villagers: Villager[];
    onSubmit: (protectVictim: boolean, additionalVictim: Villager | null) => void;
}

export function WitchCard({
    usedResurrectionPotion,
    usedDeathPotion,
    victim,
    villagers,
    onSubmit,
}: WitchCardProperties) {
    const { t } = useTranslation();
    const [protectVictim, setProtectVictim] = useState(false);
    const [killSomeone, setKillSomeone] = useState(false);
    const [additionalVictim, setAdditionalVictim] = useState<Villager | null>(null);

    return (
        <CustomCard
            title={t('narrator.witch.wakesUp')}
            buttonDisabled={killSomeone && !additionalVictim}
            onClick={() => onSubmit(protectVictim, additionalVictim)}
        >
            <FormGroup row={false}>
                {!usedResurrectionPotion && (
                    <YesNoQuestion
                        message={t('narrator.witch.wantToRescueQuestion', {
                            villager: victim.name,
                        })}
                        onAnswerChanged={answer => setProtectVictim(answer)}
                    />
                )}
                {!usedDeathPotion && (
                    <YesNoQuestion
                        message={t('narrator.witch.wantToKillQuestion')}
                        onAnswerChanged={answer => {
                            setKillSomeone(answer);
                            setAdditionalVictim(null);
                        }}
                    />
                )}
                {killSomeone && (
                    <VillagerChooser
                        message={t('narrator.witch.whoToKillQuestion')}
                        villagers={villagers.filter(
                            villager =>
                                villager.character !== Character.WITCH && villager !== victim
                        )}
                        onSelectedVillagerChanged={villager => setAdditionalVictim(villager)}
                    />
                )}
            </FormGroup>
        </CustomCard>
    );
}
