export enum Character {
    CUPID = 'character.cupid',
    GUARDIAN = 'character.guardian',
    ORDINARY_VILLAGER = 'character.ordinaryVillager',
    SEER = 'character.seer',
    WEREWOLF = 'character.werewolf',
    WITCH = 'character.witch',
}

export interface Villager {
    id: string;
    name: string;
    character: Character;
    isAlive: boolean;
}
