import {
    Avatar,
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
} from '@material-ui/core';
import React from 'react';
import { Villager } from '../game/Villager';
import PersonIcon from '@material-ui/icons/Person';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

interface PlayerDialogProperties {
    villagers: Villager[];
    couple: {
        lover1VillagerId: string | null;
        lover2VillagerId: string | null;
    };
    isOpen: boolean;
    onClose: () => void;
}

export function PlayerDialog({ villagers, couple, isOpen, onClose }: PlayerDialogProperties) {
    const { t } = useTranslation();

    const isLover = (villager: Villager) =>
        couple.lover1VillagerId === villager.id || couple.lover2VillagerId === villager.id;

    return (
        <Dialog open={isOpen} onClose={() => onClose()} fullWidth>
            <DialogTitle>{t('settings.players')}</DialogTitle>
            <List>
                {villagers.map(villager => (
                    <ListItem key={villager.name}>
                        <ListItemAvatar>
                            <Avatar>{villager.isAlive ? <PersonIcon /> : <CloseIcon />}</Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={villager.name} secondary={t(villager.character)} />
                        {isLover(villager) && (
                            <ListItemSecondaryAction>
                                <FavoriteIcon />
                            </ListItemSecondaryAction>
                        )}
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}
